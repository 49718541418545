import {
  AfterContentChecked,
  Directive,
  ElementRef,
  Input,
  inject,
} from '@angular/core';
import { Observable } from 'rxjs';
import { BaseWidgetTypeContentComponent } from '../base-widget-type-content/base-widget-type-content.component';
import { IDashboardWidgetTypeConfig } from '../models/widget-type.models';

@Directive()
export abstract class BaseChartWidget<T extends IDashboardWidgetTypeConfig>
  extends BaseWidgetTypeContentComponent<T>
  implements AfterContentChecked
{
  // abstract updateChart(): void;

  private lastWidth = 0;
  private lastHeight = 0;

  @Input()
  refreshResize: Observable<void>;

  private el = inject(ElementRef);

  ngAfterContentChecked(): void {
    if (
      this.lastWidth !== this.el.nativeElement.offsetWidth ||
      this.lastHeight !== this.el.nativeElement.offsetHeight
    ) {
      this.lastWidth = this.el.nativeElement.offsetWidth;
      this.lastHeight = this.el.nativeElement.offsetHeight;

      // this.updateChart();
    }
  }
}
