import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';

@Component({
  standalone: true,
  selector: 'bo-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule],
})
export class WidgetComponent {
  @HostBinding('class') class = 'bo-widget';

  @Input()
  public icon: IconDefinition;

  @Input()
  headerTemplate: TemplateRef<any>;
}
