import { Directive, Input, TemplateRef, ViewChild } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, filter } from 'rxjs';
import { IDashboardWidgetTypeConfig } from '../models/widget-type.models';
import { IWidgetTypeContentComponent } from '../models/widget-types-content.models';
import { IDashboardWidgetDetailDto } from '../models/widget.models';

@Directive()
export class BaseWidgetTypeContentComponent<
  T extends IDashboardWidgetTypeConfig
> implements IWidgetTypeContentComponent
{
  @ViewChild('headerTemplate')
  public headerTemplate: TemplateRef<any>;
  protected config$ = new BehaviorSubject<T>(null);
  protected configObservable = this.config$.pipe(
    filter((x) => !!x),
    distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y))
  );

  @Input()
  public set config(config: T) {
    this.config$.next(config);
  }

  public get config() {
    return this.config$.value;
  }

  protected widgetInternal: IDashboardWidgetDetailDto;
  @Input()
  public set widget(val: IDashboardWidgetDetailDto) {
    if (val) {
      this.widgetInternal = val;
    }
  }

  public get widget(): IDashboardWidgetDetailDto {
    return this.widgetInternal;
  }
}
