<gridster [options]="options">
  @for (item of widgets; track item.id) {
    <gridster-item [item]="item">
      <bo-widget class="bo-widget" [headerTemplate]="headerTemplate">
        <ng-container widget-header>
          {{ item.title | transloco }}
        </ng-container>
        <ng-template
          [ngComponentOutlet]="castToAnyType(item)"
          [ndcDynamicInputs]="item.inputs"
        ></ng-template>
      </bo-widget>
    </gridster-item>
  }
</gridster>
