<h1 class="bo-widget-header">
  @if (headerTemplate ?? defaultHeaderTemplate) {
    <ng-container
      [ngTemplateOutlet]="defaultHeaderTemplate"
    ></ng-container>
  }
</h1>

<div class="bo-widget-content">
  <ng-content></ng-content>
</div>

<ng-template #defaultHeaderTemplate>
  <ng-content select="[widget-header]"></ng-content>
</ng-template>
